<template>
  <div>
    <b-card title="Clients">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Id</th>
            <th>Enabled</th>
            <th>Type</th>
            <th>System Id</th>
            <th>Connection</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="client in asup.details.clients"
            :key="client.id"
          >
            <td>{{ client.id }}</td>
            <td>{{ client.enabled }}</td>
            <td>{{ client.type | agentClientTypeDisplayName }}</td>
            <td>
              <b-link :to="{ name: 'asset-by-serial-number-redirect', params: { serialnumber: client.systemId }, query: {ar: true}}">
                {{ client.systemId }}
              </b-link>
            </td>
            <td>{{ client.connectionString }}</td>
          </tr>
        </tbody>
      </table>
    </b-card>
  </div>
</template>

<script>

import { BCard, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BLink,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
